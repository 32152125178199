import React, { Component, Fragment } from 'react'
import { ThemeProvider } from 'styled-components'
import Theme from '../../theme'
import GlobalStyle from '../GlobalStyle'
import Routes from '../../pages/Routes'
import '../../index.scss'
import { Provider } from 'react-redux'
import store from '../../store'
import { BrowserRouter } from 'react-router-dom'
import ReactModal from 'react-modal'

ReactModal.setAppElement('#root');

class App extends Component {
  render () {
    return (
      <Provider store={store}>
        <ThemeProvider theme={Theme}>
          <Fragment>
            <GlobalStyle />
            <BrowserRouter>
              <Routes/>
            </BrowserRouter>
          </Fragment>
        </ThemeProvider>
      </Provider>
    )
  }
}

export default App
