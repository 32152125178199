export const REVERSE_COLORS = 'ade/REVERSE_COLORS'
export const STANDARD_COLORS = 'ade/STANDARD_COLORS'

export function reverseColors() {
  return { type: REVERSE_COLORS }
}
export function standardColors() {
  return { type: STANDARD_COLORS }
}

const initialState = {
  reverse: false
}

export default function appReducer(state = initialState, action) {
  switch(action.type) {
    case REVERSE_COLORS:
      document.body.classList.add('reverse')
      return Object.assign({}, state, {reverse: true})
    case STANDARD_COLORS:
      document.body.classList.remove('reverse')
      return Object.assign({}, state, {reverse: false})
    default:
      return state
  }
}
