import React from 'react'
import styled from 'styled-components'
import Header from '../../components/Header'
import { Switch, Route, withRouter } from 'react-router-dom'
import { Labs, Jobs, Landing, Studio, Thoughts } from '../index'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import cx from 'classnames'

const scrollReset = (InnerComponent) => {
  return class ScrollReset extends React.Component {
    constructor (props) {
      super(props)
      this.myRef = React.createRef()   // Create a ref object
    }

    componentDidMount () {
      this.myRef.current.scrollTo(0, 0)
    }

    render () {
      return (
        <div ref={this.myRef}>
          <InnerComponent {...this.props} />
        </div>
      )
    }
  }
}

const Main = ({className, location}) => (
  <div className={cx(className)}>
    <Header />
    <TransitionGroup className='transition-group'>
      <CSSTransition
        key={location.key}
        timeout={{enter: 300, exit: 300}}
        classNames={'page-animation'}
      >
        <section className='route-section'>
          <Switch location={location}>
            <Route path='/' exact component={scrollReset(Landing)} />
            <Route path='/jobs' exact component={scrollReset(Jobs)} />
            <Route path='/thoughts' exact component={scrollReset(Thoughts)} />
            <Route path='/labs' exact component={scrollReset(Labs)} />
            <Route path='/studio' exact component={scrollReset(Studio)} />
          </Switch>
        </section>
      </CSSTransition>
    </TransitionGroup>
  </div>
)

const ADE = styled(Main)`
  margin-top: 5em;
  page-animation-enter {
    translateY(200px);
    opacity: 0.01;
  }
  
  .page-animation-enter.page-animation-enter-active {
      opacity: 1;
      translateY(0);
      transition: all 300ms ease-in;
  }
  
  .page-animation-exit {
      translateY(0);
      opacity: 1;
  }
  
  .page-animation-exit.page-animation-exit-active {
      opacity: 0.01;
      translateY(200px);
      transition: opacity 300ms ease-in;
  }
  div.transition-group {
       position: relative;
  }
  section.route-section {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }
  background: ${props => props.theme.colors.background};
  color: ${props => props.theme.colors.foreground};
  &.reverse {
    background: ${props => props.theme.colors.foreground};
    color: ${props => props.theme.colors.background};
  }
`
export default withRouter(ADE)
