import uniq from 'lodash/uniqBy'
export const MERGE_PROJECTS = 'entries/MERGE_PROJECTS'
export const MERGE_CLIENTS = 'entries/MERGE_CLIENTS'
export const MERGE_PROCESSES = 'entries/MERGE_PROCESSES'
export const MERGE_JOBS = 'entries/MERGE_JOBS'
export const LOAD_ENTRIES = 'entries/LOAD_ENTRIES'

export const loadEntries = () => ({type: LOAD_ENTRIES})

export const mergeProjects = (entries = [], deletedEntries = []) => (
  {type: MERGE_PROJECTS,
    entries,
    deletedEntries
  })
export const mergeClients = (entries = [], deletedEntries = []) => ({
  type: MERGE_CLIENTS,
  entries,
  deletedEntries
})
export const mergeProcesses = (entries = [], deletedEntries = []) => ({
  type: MERGE_PROCESSES,
  entries,
  deletedEntries
})
export const mergeJobs = (entries = [], deletedEntries = []) => ({
  type: MERGE_JOBS,
  entries,
  deletedEntries
})


const mergeEntries = (state, action) => {
  if (!action.entries.length && !action.deletedEntries.length) return state
  let entries = action.entries.concat(state)
  const dids = action.deletedEntries.map(d => d._id)
  if (dids.length) entries = entries.filter(e => !dids.includes(e._id))
  entries = uniq(entries, e => e._id)
  return entries
}

const getEntriesReducer = (key) => (state = [], action) => {
  if(action.type === key) return mergeEntries(state, action)
  else return state
}

export default getEntriesReducer
