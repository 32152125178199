export const OPEN_MENU = 'ade/OPEN_MENU'
export const CLOSE_MENU = 'ade/CLOSE_MENU'
export const TOGGLE_MENU = 'ade/TOGGLE_MENU'

export function openMenu() {
  return { type: OPEN_MENU }
}
export function closeMenu() {
  return { type: CLOSE_MENU }
}
export function toggleMenu() {
  return { type: TOGGLE_MENU }
}
const initialState = {
  open: false
}

export default function menuReducer(state = initialState, action) {
  switch(action.type) {
    case OPEN_MENU:
      return Object.assign({}, state, {open: true})
    case CLOSE_MENU:
      return Object.assign({}, state, {open: false})
    case TOGGLE_MENU:
      return Object.assign({}, state, {open: !state.open})
    default:
      return state
  }
}
