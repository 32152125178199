import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Typography from './Typography/index'
import ADE from './ADE/index'

const Routes = () => (
    <Switch>
      <Route path='/typography' exact component={Typography} />
      <Route path='/' component={ADE} />
    </Switch>
)

export default Routes
