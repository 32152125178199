import styled from 'styled-components'
import {fontSize} from 'styled-system'
import MainNavItem from '../MainNavItem'
import React from 'react'
import {p} from '../Typography'
import {connect} from 'react-redux'
import {closeMenu} from '../../store/reducers/menu'


const ContactLink = styled.a`
  font-size: 18px;
  line-height: 1.5em;
  display: block;
`
const Items = connect(null, {closeMenu})(({className, closeMenu}) => (
  <div className={className}>
    <MainNavItem to='/studio' onClick={closeMenu}>Studio</MainNavItem>
    <MainNavItem to='/labs' onClick={closeMenu}>Lab</MainNavItem>
    <MainNavItem to='/thoughts' onClick={closeMenu}>Thoughts</MainNavItem>
    <MainNavItem to='/jobs' onClick={closeMenu}>Jobs</MainNavItem>
    <Contact fontSize={[14, 16]}>
      <p>
        You can email us at <a href='mailto:hi@weade.co'>hi@weade.co</a>
        <br />call us at <a href="tel:18664515599">+1 (866) 451-5599</a>
      </p>
      <div style={{ marginTop: 24}}>
      <h5>Studio</h5>
      <ContactLink href="https://goo.gl/maps/xhh1ormpSrC2">2120 University Ave<br />Berkeley, CA 94704</ContactLink>
      <h5 style={{marginTop: 12}}>Labs</h5>
      <ContactLink href="https://goo.gl/maps/awiSUneLk7y">Varsovia 36, Juárez, 06600<br />Cuauhtémoc, CDMX, Mexico</ContactLink>
      </div>
    </Contact>
  </div>
))

const Contact = styled.div`
  margin-top: 0em;
  > a {
    font-size: 16px;
    line-height: 16px;
  }
  h5 {
    margin-bottom: 0;
  }
  a {
    text-decoration: none;
  }
  h5, a, p {
    font-size: 1em;
  }
  &:before {
    content: ' ';
    line-height: 0.5em;
    display: inline-block;
    background: ${props => props.theme.colors.foreground};
    height: 2px;
    width: 3em;
  }
`
const MainNav = styled(Items)`
  ${fontSize};
  display: flex;
  flex-direction: column;
  line-height: 3em;
  align-items: flex-end;
  justify-content: flex-end;
  text-align: right;
  margin-right: calc(3em + ${props => props.animationOffset}px);
`
export default MainNav
