import styled from 'styled-components'
import {lighten} from 'polished'
const Hero = styled.div`
  width: 100%;
  margin-top: 2em;
  height: 70vh;
  background: ${props => lighten(0.2, props.theme.colors.background)};
  color: ${props => props.theme.colors.foreground};
  display: flex;
  align-items: center;
  justify-content: center;
`
export default Hero
