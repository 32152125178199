import styled from 'styled-components'
import {Link} from 'react-router-dom'

const MainNavItem = styled(Link)`
  text-align: right;
  font-size: 2em;
  text-decoration: none;
  position: relative;
  padding-bottom: 0em;
  &:after {
    text-align: right;
    font-size: 3em;
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: ${props => props.theme.colors.highlight};
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
  }
  
  &:hover:after {
    visibility: visible;
    transform: scaleX(1);
  }

`
export default MainNavItem
