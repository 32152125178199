import React from 'react'
import styled from 'styled-components'

const Main = ({className}) => (
  <div className={className}>
    <h1>Studio</h1>
  </div>
)

const Studio = styled(Main)`
  padding: 2em 4em;
`
export default Studio
