import gt from 'lodash/get'

export const parseAsset = (entry, language = 'en-US') => {
  return Object.keys(entry.fields).reduce((mem, key) => {
    mem[key] = entry.fields[key][language]
    return mem
  }, {_id: entry.sys.id})
}

export const parseEntry = (entry, language = 'en-US') => {
  return Object.keys(entry.fields).reduce((mem, key) => {
    const field = entry.fields[key]
    let data = field[language]
    if (Array.isArray(data)) data = data.map(d => typeof d === 'object' ? parseItem(d, language) : d)
    else if (typeof data === 'object' && data !== null) data = parseItem(data, language)
    mem[key] = data
    return mem
  }, {_id: entry.sys.id})
}

export const parseItem = (entry = {}, language = 'en-US') => {
  const type = gt(entry, 'sys.type', 'UNKNOWN')
  if (type === 'Entry') return parseEntry(entry, language)
  else if (type === 'Asset') return parseAsset(entry, language)
  else return entry
}

export const getParsedEntryByType = (entries, type, language = 'en-US') => {
  return entries.filter(e => e.sys.contentType.sys.id === type).map(e => parseItem(e, language))
}
