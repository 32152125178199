import React from 'react'
import styled from 'styled-components'
import './Typography.scss'

const th = styled.th`
  padding: 1em;
`

const Typography = () => (
  <div style={{padding: '2em 4em', display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
    <h1>ADE Typography System</h1>
    <p>
      This system is based on an Perfect Fourth scale which scales well both up and down from our primary text size.
    </p>
    <table className='Typo'>
      <tr>
        <th>Element</th>
        <th>Typeface</th>
        <th>Font</th>
        <th>Size</th>
        <th>Case</th>
        <th>Letter Spacing</th>
      </tr>
      <tr>
        <td><h1>Heading</h1></td>
        <td>Nunito Sans</td>
        <td>Light (200)</td>
        <td>3.157em</td>
        <td>Sentence</td>
        <td>-0.04em;</td>
      </tr>
      <tr>
        <td><h2>Heading</h2></td>
        <td>Nunito Sans</td>
        <td>Light (200)</td>
        <td>60</td>
        <td>Sentence</td>
        <td>-0.02em</td>
      </tr>
      <tr>
        <td><h3>Heading</h3></td>
        <td>Nunito Sans</td>
        <td>Light (200)</td>
        <td>2.369em</td>
        <td>Sentence</td>
        <td>0</td>
      </tr>
      <tr>
        <td><h4>Heading</h4></td>
        <td>Nunito Sans</td>
        <td>Light (200)</td>
        <td>1.777em</td>
        <td>Sentence</td>
        <td>0</td>
      </tr>
      <tr>
        <td><h5>Heading</h5></td>
        <td>Nunito Sans</td>
        <td>Regular (400)</td>
        <td>1.333em</td>
        <td>Sentence</td>
        <td>0</td>
      </tr>
      <tr>
        <td><h6>Heading</h6></td>
        <td>Nunito Sans</td>
        <td>Regular (400)</td>
        <td>48</td>
        <td>Uppercase</td>
        <td>0</td>
      </tr>
      <tr>
        <td><p>Paragraph</p></td>
        <td>Nunito Sans</td>
        <td>Light (200)</td>
        <td>1.414em</td>
        <td>Uppercase</td>
        <td>0</td>
      </tr>
      <tr>
        <td><p><b>Bold/Strong</b></p></td>
        <td>Nunito Sans</td>
        <td>Regular (400)</td>
        <td>1.414em</td>
        <td>Sentence</td>
        <td>0</td>
      </tr>
      <tr>
        <td><p><i>Italic</i></p></td>
        <td>Nunito Sans</td>
        <td>Regular (400)</td>
        <td>1.414em</td>
        <td>Sentence</td>
        <td>0</td>
      </tr>
    </table>
    <div style={{width: '80%'}}>
      <h1>This Is A Big Heading</h1>
      <p>
        <b>This is bold text</b> that leads into <a onClick={() => void(0)}>a link</a>. This then keeps going into some <i> a whole lot of italic text la di da whatever.</i> Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum. Praesent mauris. Fusce nec tellus sed augue semper porta. Mauris massa. Vestibulum lacinia arcu eget nulla. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur sodales ligula in libero. Sed dignissim lacinia nunc.
      </p>
      <h2>This Is A Smaller Heading</h2>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum. Praesent mauris. Fusce nec tellus sed augue semper porta. Mauris massa. Vestibulum lacinia arcu eget nulla. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur sodales ligula in libero. Sed dignissim lacinia nunc.
      </p>
      <h3>This Is A Still Smaller Header</h3>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum. Praesent mauris. Fusce nec tellus sed augue semper porta. Mauris massa. Vestibulum lacinia arcu eget nulla. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur sodales ligula in libero. Sed dignissim lacinia nunc.
      </p>
      <h4>This Is A Still Smaller Header</h4>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum. Praesent mauris. Fusce nec tellus sed augue semper porta. Mauris massa. Vestibulum lacinia arcu eget nulla. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur sodales ligula in libero. Sed dignissim lacinia nunc.
      </p>
      <h5>This Is A Still Smaller Header</h5>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum. Praesent mauris. Fusce nec tellus sed augue semper porta. Mauris massa. Vestibulum lacinia arcu eget nulla. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur sodales ligula in libero. Sed dignissim lacinia nunc.
      </p>
      <h6>This Is A Still Smaller Header</h6>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum. Praesent mauris. Fusce nec tellus sed augue semper porta. Mauris massa. Vestibulum lacinia arcu eget nulla. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur sodales ligula in libero. Sed dignissim lacinia nunc.
      </p>
    </div>
  </div>
)
export default Typography
