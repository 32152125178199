import React from 'react'
import { Flex, Box } from '@rebass/grid'
import styled from 'styled-components'
import Hamburger from 'react-hamburgers'
import { connect } from 'react-redux'
import { toggleMenu } from '../../store/reducers/menu'
import MenuModal from '../MenuModal'
import { bodyPadding } from '../GlobalStyle/GlobalStyle'
import {Link} from 'react-router-dom'
import cx from 'classnames'


const mapStateToProps = ({menu, app: {reverse}}) => ({menu, reverse})
const mapDispatchToProps = {toggleMenu}
export const HeaderHeight = '3em'

const HeaderFlex = styled(Flex)`
  height: ${HeaderHeight};
`
const HeaderSection = styled.div`
  position: fixed;
  top: 0; left: 0;
  ${bodyPadding};
  width: 100%;
  z-index: 9;
  box-sizing: border-box;
`
const Header = ({menu: {open}, toggleMenu, reverse }) => (
  <HeaderSection>
      <HeaderFlex alignItems='center' justifyContent='space-between'>
        <Box width={'3em'} css={{justifySelf: 'flex-start', marginRight: 'auto'}}><Link to='/'>ade</Link></Box>
        <Box>
          <MenuModal open={open} />
          <Hamburger type='spring' active={open} onClick={toggleMenu} className={cx('hamburger hamburger-spring', {reverse: reverse && !open})} />
        </Box>
      </HeaderFlex>
  </HeaderSection>
)

export default connect(mapStateToProps, mapDispatchToProps)(Header)

