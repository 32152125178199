const Theme = {
  font: {
    size: 18,
    fontFamily: `'Nunito Sans', sans-serif`,
    fontWeight: 200
  },
  colors: {
    foreground: '#FCFCFC',
    background: 'black',
    highlight: '#fcfcfc',
    dark: '#4d4d4d',
    mid: '#8c8c8c',
    light: '#d9d9d9',
  },
  fontSizes: [16, 14]
}

export default Theme
