import { css } from 'styled-components'

export const h1 = css`
  font-size: 4.029em;
  letter-spacing: -0.04em;
  font-weight: 200;
`
export const h2 = css`
  font-size: 3.157em;
  font-weight: 200;
  letter-spacing: -0.02em;
`

export const h3 = css`
  font-size: 2.369em;
  font-weight: 200;
`

export const h4 = css`
  font-size: 1.777em;
  font-weight: 400;
`

export const h5 = css`
  font-size: 1.333em;
  font-weight: 400;
`
export const h6 = css`
  font-weight: 400;
  text-transform: uppercase;
`
export const p = css`
  font-size: 1.3333em;
  font-weight: 200;
`
export const small = css`
  font-size: 0.75em;
`
