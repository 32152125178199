import React from 'react'
import styled from 'styled-components'

const Main = ({className}) => (
  <div className={className}>
    <h1>Labs</h1>
  </div>
)

const Labs = styled(Main)`
  padding: 2em 4em;
`
export default Labs
