import React from 'react'
import ReactModal from 'react-modal'
import { Box, Flex } from '@rebass/grid'
import Hamburger from 'react-hamburgers'
import styled from 'styled-components'
import cx from 'classnames'
export default class FullPageModal extends React.Component {
  constructor () {
    super()
    this.state = {
      open: false
    }
  }

  open () {
    this.setState({open: true})
  }

  close () {
    this.setState({open: false})
  }

  render () {
    const {children, isOpen, onClose} = this.props
    return (
      <ReactModal
        isOpen={isOpen}
        onAfterOpen={this.open.bind(this)}
        style={{
          content: {
            padding: '2em',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'black'
          }, overlay: {zIndex: 100, padding: 0, margin: 0}
        }} closeTimeoutMS={500}>
        <Modal className={cx({open: this.state.open})}>
          <Flex alignItems='flex-end' justifyContent='flex-end'>
            <Box>
              <Hamburger onClick={() => {
                this.close()
                onClose()
              }} active={true} type='spring' />
            </Box>
          </Flex>
          {children}
        </Modal>
      </ReactModal>
    )
  }
}

const Modal = styled.div`
  background: ${props => props.theme.colors.background};
  color: ${props => props.theme.colors.foreground};
  box-sizing: border-box;
  padding: 2em;
  transform: translateY(100px) scale(0.95);
  opacity: 0.5;
  transition: all 500ms ease;
  &.open  {
    transform: translateY(0) scale(1);
    opacity: 1;
    transition: all 500ms ease;
  }
`
