import { createGlobalStyle, css } from 'styled-components'
import reset from 'styled-reset'
import { h1, h2, h3, h4, h5, h6 } from '../../components/Typography'

const GlobalStyle = createGlobalStyle`
  ${reset}
  body {
    background: ${props => props.theme.colors.background};
    color: ${props => props.theme.colors.foreground};
    font-size: ${props => props.theme.font.fontSize};
    font-family: ${props => props.theme.font.fontFamily};
    font-weight: ${props => props.theme.font.fontWeight};
    line-height: 1.5em;
    &.reverse {
      background: ${props => props.theme.colors.foreground};
    }
  }
 
  p {
    margin-bottom: 1.5em;
    line-height: 1.5em;
    font-size: 1.414em;
  }
  ul {
    list-style-type: disc;
  }
  li {
    margin-bottom: 1em;
    line-height: 1.5em;
    font-size: 1.414em;
    margin-left: 2em;
  }
  i {
    font-style: italic;
    font-weight: 300;
  }
  a { 
    color: ${props => props.theme.colors.highlight};
     &:hover {
      opacity: 0.7;
      transition: opacity .5s ease-out;
    }
  }
  b, strong {
    font-weight: 600;
  }
  button {
    outline: none;
  }
  * {
    box-sizing: border-box;
  }
  h1, h2, h3, h4, h5, h6 {
    line-height: 1em;
     padding-bottom: 0.5rem;
     margin-bottom: 1rem;
  }
  /** 
    Typography
   */
   h1 { ${h1} }
   h2 { ${h2} }
   h3 { ${h3} }
   h4 { ${h4} }
   h5 { ${h5} }
   h6 { ${h6} }
`

export const bodyPadding = css`
  padding: 2em 4em;
`
export default GlobalStyle

