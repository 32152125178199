import { call, put, takeLatest } from 'redux-saga/effects'
import { syncContent } from '../../lib/contentful'
import {
  mergeClients,
  mergeProjects,
  mergeProcesses,
  mergeJobs,
  LOAD_ENTRIES
} from '../reducers/entries'
import { getParsedEntryByType } from '../../lib/utils'

export function * fetchEntries () {
  try {
    const {entries, deletedEntries} = yield call(syncContent)
    yield put(mergeClients(getParsedEntryByType(entries, 'client'), getParsedEntryByType(deletedEntries, 'client')))
    yield put(mergeProjects(getParsedEntryByType(entries, 'project'), getParsedEntryByType(deletedEntries, 'project')))
    yield put(mergeJobs(getParsedEntryByType(entries, 'job'), getParsedEntryByType(deletedEntries, 'job')))
    yield put(mergeProcesses(getParsedEntryByType(entries, 'process'), getParsedEntryByType(deletedEntries, 'process')))
  } catch (e) {
    console.error(e)
  }
}

const projectSagas = [
  takeLatest(LOAD_ENTRIES, fetchEntries)
]

export default projectSagas
