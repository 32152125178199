import styled from 'styled-components'
export default styled.div`
  display: flex;
  flex-direction: column;
  padding: 4em 2em;
  align-items: center;
  flex-grow: 1;
  > * {
    width: 100%;
  }
`
