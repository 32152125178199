import { combineReducers } from 'redux'
import menu from './menu'
import app from './app'
import getEntriesReducer, {
  MERGE_CLIENTS,
  MERGE_JOBS,
  MERGE_PROCESSES,
  MERGE_PROJECTS
} from './entries'

export default combineReducers({
  menu, app,
  projects: getEntriesReducer(MERGE_PROJECTS),
  jobs: getEntriesReducer(MERGE_JOBS),
  clients: getEntriesReducer(MERGE_CLIENTS),
  processes: getEntriesReducer(MERGE_PROCESSES)
})
