import styled from 'styled-components'
import {lighten} from 'polished'
import {h1} from '../Typography'

export default styled.div`
  margin: 4em 0;
  background-image: url(${props => props.backgroundImage});
  background-position: center center;
  background-size: cover;
  height: 20em;
  background: ${props => lighten(0.2, props.theme.colors.background)};
  color: ${props => props.theme.colors.foreground};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &:after {
    content: '${props => props.title}';
    ${h1};
  }
`
