import React from 'react'
import styled from 'styled-components'
import Hero from '../../components/Hero'
import ProjectMasonry from '../../components/ProjectMasonry'

const Main = ({className}) => (
  <div className={className}>
    <Hero><h1>We make cool stuff</h1></Hero>
    <ProjectMasonry />
  </div>
)

const Landing = styled(Main)`
  padding: 2em 4em;
`
export default Landing
