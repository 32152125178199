import React from 'react'
import styled from 'styled-components'
import { CSSTransition } from 'react-transition-group'
import {closeMenu} from '../../store/reducers/menu'
import MainNav from '../MainNav'
import { connect } from 'react-redux'

const mapStateToProps = ({menu: {open}}) => ({open})
const transitionName = 'modal-animation'
const appearDuration = 250
const fadeDuration = 250
const animationOffset = 100

const MenuModal = connect(mapStateToProps, {closeMenu})(({className, open, closeMenu}) => (
  <CSSTransition
    in={open}
    classNames={transitionName}
    timeout={appearDuration}
    unmountOnExit>
    <div className={className} onClick={closeMenu}>
      <MainNav fontSize={[14, 16]} animationOffset={animationOffset} />
    </div>
  </CSSTransition>
))

export default styled(MenuModal)`
  &.${transitionName}-enter {
    opacity: 0;
    transform: translateX(-${animationOffset}px)
  }

  &.${transitionName}-enter-active {
      opacity: 1;
      transition: all ${fadeDuration}ms ease-out;
      transform: translateX(0)
  }
  &.${transitionName}-exit {
    opacity: 1;
    transform: translateX(0)
  }

  &.${transitionName}-exit-active {
      opacity: 0;
      transition: all ${fadeDuration}ms ease-out;
      transform: translateX(-${animationOffset}px)
  }

  height: 100vh;
  width: calc(100vw + ${animationOffset}px);
  background: ${props => props.theme.colors.background};
  position: absolute;
  top: 0; left: 0;
  padding: 2em 4em;
`
