import React from 'react'
import Banner from '../../components/Banner'
import styled from 'styled-components'
import { loremIpsum } from 'lorem-ipsum'
import { reverseColors, standardColors } from '../../store/reducers/app'
import { Flex, Box } from '@rebass/grid'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import Parser from 'html-react-parser'
import Markdown from 'react-markdown'
import { h3, h5 } from '../../components/Typography'
import FullPageModal from '../../components/FullPageModal'

const mapStateToProps = (state, { match: {params} }) => {
  const out = {hasJobId: false, jobs: state.jobs}
  if(params.id) {
    out.hasJobId = true
    out.job = state.jobs.find(j => j._id === params.id)
  }
  return out
 }
const about = loremIpsum({
  count: 2,
  format: 'html',
  paragraphsLowerBound: 5,
  paragraphUpperBound: 10,
  sentenceLowerBound: 5,   // Min. number of words per sentence.
  sentenceUpperBound: 15,
  units: 'paragraphs'
})

const ApplyLink = styled.a`
  ${h5}
  text-decoration: none;
`
const Desc = styled.div`
  > h1 {
    ${h3}
  }
`
const renderJob = (job) => {
  if (!job) {
    return (
      <React.Fragment>
        <h3>Could Not Find That Position</h3>
        <h4>That job may no longer be open</h4>\
        <p>
          Sorry, please continue to browse our current openings for another opportunity.
        </p>
      </React.Fragment>
    )
  } else {
    return (
      <React.Fragment>
        <Flex mb='3em' mt='1em'>
          <Box width={[1, 2 / 3]}>
            <h3>{job.title}</h3>
            <h4>{job.location.join(' / ')}</h4>
          </Box>
          <Box width={[1, 1 / 3]} css={{textAlign: 'right'}}>
            <ApplyLink href={job.applicationLink} target='_blank'>Apply Here</ApplyLink>
          </Box>
        </Flex>
        <Desc><Markdown source={job.description} /></Desc>
        <Flex mt='3em' alignItems={'flex-end'} justifyContent='flex-end'>
          <Box width={[1, 1 / 3]} css={{textAlign: 'right'}}>
            <ApplyLink href={job.applicationLink} target='_blank'>Apply Here</ApplyLink>
          </Box>
        </Flex>
      </React.Fragment>
    )
  }
}

const ModalBox = styled(Box)`
  color: ${props => props.theme.colors.background};
  background-color: transparent;
  &:hover {
    background-color: ${props => props.theme.colors.background};
    color: ${props => props.theme.colors.foreground};
    transition: all 300ms ease-in;
    > a {
      color: ${props => props.theme.colors.foreground};
    }
  }
  > a {
     color: ${props => props.theme.colors.background};
     &:hover {
      color: ${props => props.theme.colors.foreground};
     }
  }
  text-decoration: none;
`
const ModalLink = styled(Link)`
  text-decoration: none;
  box-sizing: border-box;
  padding: 2em;
  display: inline-block;
`



const StyleJobs = styled.div`
  padding: 2em 4em;
`

class Jobs extends React.Component {
  constructor () {
    super()
    this.state = {
      open: false
    }
  }


  render () {
    const {job, hasJobId, jobs, history: {replace}} = this.props
    return (
      <StyleJobs>
        <FullPageModal isOpen={hasJobId} onClose={() => replace('/jobs')}>
          {renderJob(job)}
        </FullPageModal>
        <Banner title={'Jobs Banner'} />
        <Flex>
          <Box>
            <h3>Working at ADE</h3>
            {Parser(about)}
          </Box>
        </Flex>
        <Flex justifyContent={'center'}>
          <Box css={{maxWidth: 1440}} width={'100%'} alignSelf={'center'}>
            <h3>Current Job Openings</h3>
          </Box>
        </Flex>
        <Flex justifyContent={'center'}>
          <Box css={{maxWidth: 1440}} width={'100%'} alignSelf={'center'}>
            <Flex alignItems='center' flexWrap='wrap'>
              {jobs.map(j => (
                <ModalBox width={[1, 1 / 2, 1 / 4]} px={[1, 2]} py={[1, 2]} key={`job:${j._id}`}>
                  <ModalLink to={`/jobs/${j._id}`}>
                    <h5>{j.title}</h5>
                    <h6>{j.location}</h6>
                  </ModalLink>
                </ModalBox>
              ))}
            </Flex>
          </Box>
        </Flex>
      </StyleJobs>
    )
  }
}

export default connect(mapStateToProps, {reverseColors, standardColors})(withRouter(Jobs))
