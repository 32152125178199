import React from 'react'
import styled from 'styled-components'
import { lighten, transparentize} from 'polished'

const lightOpacity = (color, light, opacity) => transparentize(opacity, lighten(light, color))
const Brick = styled.div`
  flex: auto;
  height: 250px;
  min-width: 150px;
  line-height: 250px;
  text-align: center;
  color: ${props => props.theme.colors.foreground}
  width: (40vw - 4em);
  min-width: 200px;
  &:nth-child(3n+2) {
    width: calc(40vw - 4em);
  }
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center center;
  position: relative;
`
const Overlay = styled.div`
  position: absolute;
  top: 0; left: 0; width: 100%; height: 100%;
  background-color: ${props => lightOpacity(props.theme.colors.background, 0.2, 0.1)};
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    width: 10vw;
  }
  &:hover {
    opacity: 0;
    transition: opacity 300ms ease-in;
  }
`
const Underlay = styled.div`
  position: absolute;
  background-position: center center;
  background-size: cover;
  top: 0; left: 0; width: 100%; height: 100%;
 
  &:before {
    content: ' ';
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background-color: ${props => lightOpacity(props.theme.colors.background, 0.2, 0.2)};
  }
  > div {
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    color: ${props => props.theme.colors.foreground};
    text-align: right;
    padding: 1em;
    box-sizing: border-box;
    font-size: 12px;
    > h5 {
      margin-bottom: 0em;
    }
  }
`
const ProjectBrick = ({project}) => (
  <Brick>
    <Underlay style={{backgroundImage: `url(${project.worksImage.file.url})`}}>
      <div>
        <h5>{project.name}</h5>
        <p>{project.blurb}</p>
      </div>
    </Underlay>
    <Overlay>
      <img src={project.client.logo.file.url} alt={project.client.name} />
    </Overlay>
  </Brick>
)

export default ProjectBrick
