import { createClient } from 'contentful'
import storage from 'store'


const client = createClient({
  space: 'v32iivr7my4x',
  accessToken: '3e196d33c8cd541b3a540d8b5672c9b4d8c5f78579ea7904d4f7b928a357ff18',
})

const request = async (opts = {}) => {
  try {
    return await client.getEntries(opts)
  } catch (e) {
    console.error('Error loading entries')
    throw e
  }
}
// const LANGUAGE = 'en-US'

export const syncContent = () => {
  const SYNC_TOKEN = storage.get('contentfulSyncToken')
  const syncConfig = SYNC_TOKEN ? {nextSyncToken: SYNC_TOKEN} : {initial: true}
  // const syncConfig = {initial: true}
  return client.sync(syncConfig)
    .then((response) => {
      storage.set('contentfulSyncToken', response.nextSyncToken)
      return response
    })
}

export const getProjects = async () => {
  const projects = await request({
    content_type: 'projects'
  })
  return projects
}
