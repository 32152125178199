import React from 'react'
import styled from 'styled-components'
import { Box } from '../../components/Grid'
import { ReadableFlex } from '../../components/Grid/Flex'
import Page from '../../components/Page'
import faker from 'faker'

const DateBox = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 1em 2em;
`

const DateH5 = styled.h5`
  margin-bottom: 0;
`

const TitleBox = styled(Box)`
  // border-left: 3px solid ${props => props.theme.colors.foreground};
  padding-left: 3em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  > h6 {
    margin-bottom: 0;
  }
  > h2 {
    margin-bottom: 0.25em;
  }
`

const articles = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => ({
  title: faker.lorem.words(3),
  subtitle: faker.lorem.slug()
}))

const Thoughts = ({className}) => (
  <Page className={className}>
    <ReadableFlex mb='3em'>
      <Box pl={'3em'}><h4>We Make Thoughts</h4></Box>
    </ReadableFlex>
    {articles.map((a, idx) => (
      <ReadableFlex alignItems='center' mb={5} key={`thought:${idx}`}>
        <DateBox mr={3}><DateH5>Mar</DateH5>
          <small>27</small>
        </DateBox>
        <TitleBox><h2>{a.title}</h2><h6>{a.subtitle}</h6></TitleBox>
      </ReadableFlex>
    ))}
  </Page>
)

export default Thoughts
