import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import Brick from './components/Brick'
const mapStateToProps = ({projects}) => ({projects})

const Masonry = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100vw;
  margin-top: 5em;
  margin-left: -4em;
`


const ProjectMasonry = ({projects}) => (
  <Masonry>
    {projects.slice(0,8).map(p => (<Brick key={p._id} project={p} />))}
  </Masonry>
)

export default connect(mapStateToProps)(ProjectMasonry)
